import { buttonGroupClasses } from '@mui/material/ButtonGroup';
import { blueGrey, grey, orange } from '@mui/material/colors';
import type { Theme, ThemeOptions } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import type { SxProps, SystemCssProperties } from '@mui/system';
import { deepmerge } from '@mui/utils';
import type { } from '@mui/x-data-grid-premium/themeAugmentation';
import EnvoriaLoadingIcon from 'components/v2/EnvoriaLoadingIcon';
import { CSSProperties } from 'react';
import DMSansBoldTTF from 'resources/fonts/DMSans-Bold.ttf';
import DMSansBoldItalicTTF from 'resources/fonts/DMSans-BoldItalic.ttf';
import DMSansItalicTTF from 'resources/fonts/DMSans-Italic.ttf';
import DMSansRegularTTF from 'resources/fonts/DMSans-Regular.ttf';
import GTAmericaExtendedRegularTTF from 'resources/fonts/GTAmerica-ExtendedRegular.ttf';
import GTAmericaExtendedRegularWOFF from 'resources/fonts/GTAmerica-ExtendedRegular.woff';
import GTAmericaExtendedRegularWOFF2 from 'resources/fonts/GTAmerica-ExtendedRegular.woff2';
import type { CustomPaletteOptions, CustomThemeOptions, ThemePaletteMode, UserTheme } from 'types/theme';

const DMSansName = '"DM Sans"';
const GTAmericaName = '"GT America"';

const GTAmericaExtendedRegular = {
   fontFamily: GTAmericaName,
   fontStyle: 'normal',
   fontWeight: 'normal',
   src: `
    url(${GTAmericaExtendedRegularWOFF2}) format('woff2'),
    url(${GTAmericaExtendedRegularWOFF}) format('woff'),
    url(${GTAmericaExtendedRegularTTF}) format('truetype')
  `,
};

const DMSansRegular = {
   fontFamily: DMSansName,
   fontStyle: 'normal',
   fontWeight: 'normal',
   src: `
    url(${DMSansRegularTTF}) format('truetype')
  `,
};

const DMSansBold = {
   fontFamily: DMSansName,
   fontStyle: 'normal',
   fontWeight: 'bold',
   src: `
    url(${DMSansBoldTTF}) format('truetype')
  `,
};

const DMSansItalic = {
   fontFamily: DMSansName,
   fontStyle: 'italic',
   fontWeight: 'normal',
   src: `
    url(${DMSansItalicTTF}) format('truetype')
  `,
};

const DMSansBoldItalic = {
   fontFamily: DMSansName,
   fontStyle: 'italic',
   fontWeight: 'bold',
   src: `
    url(${DMSansBoldItalicTTF}) format('truetype')
  `,
};

const darkModePalette: CustomPaletteOptions = {
   background: {
      default: '#111315',
      paper: '#141821',
   },
   grey: {
      main: grey[300],
      dark: grey[400],
   },
   blueGrey,
   orange,
   text: {
      primary: '#FFFFFF',
      secondary: '#ADB4BD',
   },
   button: {
      border: {
         main: 'rgba(255, 255, 255, 0.23)',
      },
   },
   primary: {
      light: '#FFFFFF',
      main: '#336CFF',
      dark: '#141821',
      opaque: '#282f3d',
      menu: '#909CB5',
   },
   secondary: {
      main: '#59DB7E',
      menu: '#00E0D7',
   },
   error: {
      main: '#EB5757',
      secondary: '#E14992',
   },
   warning: {
      main: '#F2C94C',
   },
   success: {
      main: '#59DB7E',
   },
   info: {
      main: '#3971FF',
   },
};

const lightModePalette: CustomPaletteOptions = {
   background: { default: '#F4F8FD', paper: '#FFFFFF' },
   grey: {
      main: grey[300],
      dark: grey[600],
   },
   blueGrey,
   orange,
   text: {
      primary: '#0A284B',
      secondary: '#3B618A',
   },
   button: {
      border: {
         main: '#CCD8E1',
      },
   },
   primary: {
      light: '#7AA8D8',
      main: '#336CFF',
      dark: '#141821',
      opaque: '#282f3d',
      menu: '#909CB5',
   },
   secondary: {
      main: '#59DB7E',
      menu: '#00E0D7',
   },
   error: {
      main: '#EB5757',
      secondary: '#E14992',
   },
   warning: {
      main: '#F2C94C',
   },
   success: {
      main: '#59DB7E',
   },
   info: {
      main: '#3971FF',
   },
};

const getModeThemePalette = (mode: ThemePaletteMode) =>
   mode === 'light'
      ? {
           ...lightModePalette,
        }
      : {
           ...darkModePalette,
        };

export default function envoriaTheme(mode: ThemePaletteMode, userTheme: UserTheme | null = null) {
   const modeThemePalette = getModeThemePalette(mode);
   const userThemeModePalette = userTheme ? userTheme?.[mode]?.palette : {};

   const mergedThemePalette = deepmerge(modeThemePalette, userThemeModePalette);

   const baseConfig: CustomThemeOptions = {
      palette: {
         mode,
         ...mergedThemePalette,
      },
      typography: {
         fontFamily: [DMSansName, GTAmericaName, 'Roboto', 'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(','),
         h1: {
            fontFamily: GTAmericaName,
         },
         h2: {
            fontFamily: GTAmericaName,
         },
         h3: {
            fontFamily: GTAmericaName,
         },
         h4: {
            fontFamily: GTAmericaName,
         },
         h5: {
            fontFamily: GTAmericaName,
         },
         h6: {
            fontFamily: DMSansName,
         },
         button: {
            fontFamily: DMSansName,
         },
         subtitle1: {
            fontFamily: DMSansName,
         },
         subtitle2: {
            fontFamily: DMSansName,
         },
         body1: {
            fontSize: '0.8rem',
            fontFamily: DMSansName,
         },
         body2: {
            fontFamily: DMSansName,
         },
         paragraph: {
            fontFamily: DMSansName,
         },
         caption: {
            fontFamily: DMSansName,
         },
         overline: {
            fontFamily: DMSansName,
         },
      },
   };

   const theme = createTheme(baseConfig);

   const extension: ThemeOptions = {
      shadows: {
         ...theme.shadows,
         1: '0px 2px 2px rgba(21, 56, 92, 0.14), 0px 1px 5px rgba(21, 56, 92, 0.12)',
      },
      mixins: {
         toolbar: {
            minHeight: 70,
            '@media (min-width:600px)': {
               minHeight: 70,
               background: theme.palette.background.paper,
               paddingRight: 24,
            },
            paddingRight: 24,
            background: theme.palette.background.paper,
         },
      },
      components: {
         MuiDialog: {
            defaultProps: {
               'aria-autocomplete': 'none',
               component: 'form',
               PaperProps: {
                  sx: {
                     overflow: 'hidden',
                  },
               },
            },
         },
         MuiCheckbox: {
            defaultProps: {
               disableRipple: true,
            },
         },
         MuiContainer: {
            styleOverrides: {
               root: {
                  paddingTop: theme.spacing(4),
                  paddingBottom: theme.spacing(4),
               },
            },
         },
         MuiCssBaseline: {
            styleOverrides: {
               '@font-face': DMSansRegular,
               fallbacks: [
                  {
                     '@font-face': DMSansItalic,
                  },
                  {
                     '@font-face': DMSansBold,
                  },
                  {
                     '@font-face': DMSansBoldItalic,
                  },
                  {
                     '@font-face': GTAmericaExtendedRegular,
                  },
               ],
               a: {
                  color: theme.palette.mode === 'dark' ? theme.palette.info.main : '',
               },
               '.ck.ck-toolbar, .ck.ck-content': {
                  background: `transparent !important`,
               },
               '.ck.ck-button, a.ck.ck-button': {
                  color: theme.palette.mode === 'dark' ? `${theme.palette.text.secondary} !important` : 'inherit',
               },
            },
         },
         MuiFormHelperText: {
            styleOverrides: {
               root: {
                  '&.MuiAlert-standardPrimary': {
                     backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : undefined,
                  },
               },
            },
         },
         MuiTabs: {
            styleOverrides: {
               indicator: {
                  backgroundColor: 'transparent',
               },
            },
         },
         MuiTab: {
            defaultProps: {
               disableRipple: true,
            },
            styleOverrides: {
               root: {
                  textTransform: 'none',
                  '&.Mui-selected::after': {
                     content: '""',
                     position: 'absolute',
                     bottom: 0,
                     borderBottom: 'solid 2px',
                     width: '85%',
                  },
               },
            },
         },
         MuiDataGrid: {
            styleOverrides: {
               root: {
                  backgroundColor: theme.palette.background.paper,
               },
            },
            defaultProps: {
               slots: {
                  loadingOverlay: EnvoriaLoadingIcon,
               },
            },
         },
         MuiAppBar: {
            styleOverrides: {
               root: {
                  zIndex: theme.zIndex.drawer + 1,
                  width: `calc(100% - 96px)`,
                  transition: theme.transitions.create(['width', 'margin'], {
                     easing: theme.transitions.easing.sharp,
                     duration: theme.transitions.duration.leavingScreen,
                  }),
                  borderBottomWidth: 1,
                  borderBottomStyle: 'solid',
                  borderBottomColor: theme.palette.grey[400],
               },
               colorPrimary: {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.background.paper,
               },
            },
         },
         MuiInputBase: {
            styleOverrides: {
               root: {
                  /* Firefox */
                  '& input[type=number]': {
                     MozAppearance: 'textfield',
                  },
                  '& input': {
                     /* Chrome, Safari, Edge, Opera */
                     '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                        WebkitAppearance: 'none',
                        margin: 0,
                     },
                  },
               },
            },
         },
         MuiTextField: {
            styleOverrides: {
               root: {
                  color: theme.palette.text.primary, // each theme has its own text.primary color
                  '& svg:not(.Mui-error)': {
                     fill: theme.palette.mode === 'dark' ? theme.palette.primary.menu : theme.palette.blueGrey[400],
                     fontSize: 20,
                  },
                  '& .MuiFormLabel-root': {
                     '&.MuiInputLabel-root:not(.Mui-disabled)': {
                        color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
                     },
                     '&.MuiInputLabel-shrink': {
                        fontSize: '1rem',
                     },
                     '&:not(.Mui-focused) &:not(.Mui-disabled)': {
                        color: theme.palette.mode === 'dark' ? theme.palette.grey.dark : 'inherit',
                     },
                     '&.Mui-focused': {
                        color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.info.main,
                     },
                     '&.Mui-error': {
                        color: theme.palette.error.main,
                     },
                  },

                  '& .Mui-error.Mui-focused > fieldset': {
                     border: `2px solid ${theme.palette.error.main}`,
                  },

                  '& input': {
                     // color: theme.palette.text.primary,
                     /* Chrome, Safari, Edge, Opera */
                     '&:-webkit-autofill': {
                        WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.paper} inset`,
                     },
                     '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                        WebkitAppearance: 'none',
                        margin: 0,
                     },
                  },

                  /* Firefox */
                  '& input[type=number]': {
                     MozAppearance: 'textfield',
                  },

                  '& .MuiFormHelperText-root.Mui-error': {
                     marginBottom: '12px',
                  },

                  '& fieldset > legend': {
                     fontSize: '0.76rem',
                  },
               },
            },
         },
         MuiButtonGroup: {
            defaultProps: {
               disableRipple: true,
            },
            variants: [
               {
                  props: { variant: 'outlined' },
                  style: {
                     borderColor: theme.palette.button.border.main,
                     '&:hover': {
                        borderColor: theme.palette.button.border.main,
                     },
                     [`& .${buttonGroupClasses.firstButton}:hover`]: {
                        borderColor: theme.palette.button.border.main,
                     },
                     [`& .${buttonGroupClasses.middleButton}:hover`]: {
                        borderColor: theme.palette.button.border.main,
                     },
                  },
               },
            ],
         },
         MuiButtonBase: {
            defaultProps: {
               disableRipple: true,
            },
            styleOverrides: {
               root: {
                  textTransform: 'none',
                  fontWeight: 500,
                  fontSize: '15px',
                  borderRadius: '4px',
                  padding: '9px 22px',
                  lineHeight: '160%',
               },
            },
         },
         MuiButton: {
            variants: [
               {
                  props: { variant: 'outlined' },
                  style: {
                     color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
                     borderColor: theme.palette.button.border.main,
                     '&:hover': {
                        borderColor: theme.palette.button.border.main,
                     },
                  },
               },
            ],
            defaultProps: {
               disableElevation: true,
               disableRipple: true,
            },
            styleOverrides: {
               root: {
                  fontFamily: DMSansName,
                  fontSize: 'medium',
                  transition: '0.3s',
                  cursor: 'pointer',
                  textDecoration: 'none',
                  textTransform: 'none',
                  borderRadius: 3,
                  minWidth: 123,
                  '&:hover': {
                     opacity: 0.7,
                  },
                  '&$disabled': {
                     cursor: 'not-allowed',
                     pointerEvents: 'auto',
                  },
               },
               startIcon: {
                  width: 20,
                  height: 20,
               },
               endIcon: {
                  width: 20,
                  height: 20,
               },
               contained: {
                  color: theme.palette.common.white,
               },
               containedSecondary: {
                  color: theme.palette.common.white,
               },
               containedPrimary: ({ ownerState }: { ownerState: { variant?: string; style?: CSSProperties; sx?: SxProps<Theme> | null } }) => ({
                  ...(ownerState?.variant === 'contained' && {
                     '&:hover': {
                        backgroundColor:
                           ownerState.style?.backgroundColor ??
                           (ownerState.sx as SystemCssProperties<Theme>)?.backgroundColor ?? // NOTE: bypassing check as we know it's a SystemCssProperties
                           theme.palette.primary.main,
                     },
                  }),
               }),
            },
         },
         MuiTableCell: {
            styleOverrides: {
               head: {
                  fontFamily: DMSansName,
                  color: theme.palette.text.secondary,
                  backgroundColor: theme.palette.background.paper,
                  textTransform: 'uppercase',
                  '& .MuiTableSortLabel-root, & .MuiTableSortLabel-root.Mui-active': {
                     textTransform: 'uppercase',
                     color: theme.palette.text.secondary,
                  },
               },
               body: {
                  color: theme.palette.text.primary,
               },
            },
         },
         MuiIcon: {
            styleOverrides: {
               root: {
                  // Match 24px = 3 * 2 + 1.125 * 16
                  boxSizing: 'content-box',
                  padding: 3,
                  fontSize: '1.125rem',
               },
            },
         },
         MuiIconButton: {
            styleOverrides: {
               root: ({ ownerState }: { ownerState: { color?: string } }) => ({
                  ...(ownerState.color === 'default' && {
                     color: theme.palette.mode === 'dark' ? theme.palette.primary.menu : theme.palette.blueGrey[400],
                  }),
               }),
            },
         },
         MuiDialogContent: {
            defaultProps: {
               dividers: true,
            },
            // TODO remove once the issue is addressed: https://github.com/mui-org/material-ui/issues/29892
            styleOverrides: {
               root: {
                  paddingTop: `10px !important`,
                  borderTopColor: 'transparent',
               },
            },
         },
         /* MuiPaper: {
         styleOverrides: {
            root: {
            color: theme.palette.mode === 'dark' ? theme.palette.primary.menu : theme.palette.blueGrey[400],
            },
         },
      }, */
         MuiTablePagination: {
            styleOverrides: {
               toolbar: {
                  color: theme.palette.mode === 'dark' ? theme.palette.primary.menu : 'inherit',
                  '& .MuiInputBase-root': {
                     border: `1px solid ${theme.palette.grey[400]}`,
                     borderRadius: '4px',
                     padding: '2px',
                     '& .MuiTablePagination-select': {
                        textAlign: 'initial',
                        textAlignLast: 'initial',
                        color: theme.palette.mode === 'dark' ? theme.palette.common.white : 'inherit',
                     },
                     '& svg': {
                        color: theme.palette.mode === 'dark' ? theme.palette.primary.menu : 'inherit',
                        right: '2px',
                     },
                  },
               },
            },
         },
      },
   };
   return createTheme(theme, extension);
}
